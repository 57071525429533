@import "~antd/dist/antd.css";
@import "react-quill/dist/quill.snow.css";

body {
  background: #f0f2f5 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ql-editor p {
  line-height: 1.6em !important;
  margin-bottom: 1.6em !important;
}

.ant-table table {
  font-size: 12px;
}

.ant-table .ant-tag {
  font-size: 12px;
  line-height: 16px;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  width: calc(25% - 12px) !important;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(25% - 4px) !important;
  width: calc(72% - 4px) !important;
}

div.timeline_container div.ant-timeline-item-tail,
div.ant-timeline-item-head {
  left: 25% !important;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: 25% !important;
}
